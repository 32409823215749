import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { UserService } from '../../../core/services/UserService';
import { IAccountSettings } from '../../../core/interfaces/user/IAccountSettings';
import { IUser } from '../../../core/interfaces/user/IUser';

@Component({
    selector: 'add-missing-reciever-dialog',
  templateUrl: 'add-missing-reciever-Dialog.Component.html'
})

export class AddMissingRecieverDialogComponent {

  private _formValid = false;
  errorMessage: string = null;
  accountSettings: IUser = {} as IUser;
  IsCompanyLocal: any;

  personalNumberIsValid: boolean = false;
  errorMessagePersonalNumber: string = '';
  
  organizationNumberIsValid: boolean = false;
  errorMessageOrganizationNumber: string = '';

    constructor(
      public dialogRef: MatDialogRef<AddMissingRecieverDialogComponent>,
      private userService: UserService  ,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    {}

  ngOnInit() {

    this.accountSettings.PersonalNumber = this.data.contact.IDNumber;

    if (this.checkPersonalNumber(this.accountSettings.PersonalNumber)) {
      this.IsCompanyLocal = false;
      this.GetPersonFromSpar(this.accountSettings.PersonalNumber);
    }
    //else if (this.checkOrganizationNumber(this.accountSettings.OrgNumber)) {
    //  this.IsCompanyLocal = true;
    //  //this.GetCompanyFromHitta(this.accountSettings.OrgNumber);
    //}
    //else if (this.checkOrganizationNumber(this.accountSettings.PersonalNumber)) {
    //  this.IsCompanyLocal = true;
    //  this.accountSettings.OrgNumber = this.accountSettings.PersonalNumber;
    //  //this.GetCompanyFromHitta(this.accountSettings.OrgNumber);
    //}
    
  }

  onSubmit() {
    this.addUserAccount();
  }

  onPersonalNumberUpdated(personalNumber: string) {
    
    if (this.checkPersonalNumber(personalNumber)) {
      this.GetPersonFromSpar(personalNumber);
    }
    else {
      this.accountSettings.FirstName = null;
      this.accountSettings.LastName = null;
    }
  }

  onOrganizationNumberUpdated(organizationNumber: string) {

    if (this.checkOrganizationNumber(organizationNumber)) {
      //this.GetOrganizationFromHitta(organizationNumber);
    }
    else {
      this.accountSettings.CompanyName = null;
    }
  }

  GetPersonFromSpar(personalNumber: string) {

    if (personalNumber == null || personalNumber == "") {
      return;
    }

    personalNumber = personalNumber.replace("-", "");

    this.userService.getPersonFromSparByPersonalNumber(personalNumber)
      .subscribe(
        response => this.onGetPersonFromSparCompleted(response, personalNumber),
        error => this.onGetPersonFromSparError(error));
  }

  onGetPersonFromSparError(error) {
    alert('Err')
  }

  onGetPersonFromSparCompleted(response, term) {

    if (response.hits == 1) {
      this.accountSettings.FirstName = response.firstName;
      this.accountSettings.LastName = response.lastName;
    }
    else {
      this.errorMessagePersonalNumber = 'Ingen träff i SPAR-databasen, kontrollera personnumret!'
    }

  }

  checkPersonalNumber(personalNumber: string) {

    if (personalNumber != null) {

      const value: string = personalNumber || '';
      
      if (value.match(/^^[0-9]{12}$/g)) {
        this.errorMessagePersonalNumber = "";
        this.personalNumberIsValid = true;
        return this.personalNumberIsValid
      }
      else {
        this.errorMessagePersonalNumber = "Personnumret måste vara i formatet ÅÅÅÅMMDDNNNN (12 siffror utan bindestreck)";
        this.personalNumberIsValid = false;
        return this.personalNumberIsValid;
      }
    }
  }

  checkOrganizationNumber(orgNumber: string) {

    if (orgNumber != null) {

      const value: string = orgNumber || '';
      
      if (value.match(/^[0-9]{6}-[0-9]{4}$/g)) {
        this.organizationNumberIsValid = true;
        this.errorMessageOrganizationNumber = null;
        return this.organizationNumberIsValid;
      }
      else {
        this.errorMessageOrganizationNumber = "Organisationsnumret måste vara i formatet NNNNNN-NNNN (10 siffror och vanligt bindestreck)";
        this.organizationNumberIsValid = false;
        return this.organizationNumberIsValid;
      }
      
    }

    return false;
  }

  addUserAccount() {
    var user = { UserName: this.accountSettings.Email, Email: this.accountSettings.Email, EmailTemplate: "" } as IUser;

    user = this.accountSettings;
    user.PersonalNumber = this.accountSettings.PersonalNumber;
    user.EmailTemplate = "WelcomeUserAddedBy3P";
    user.FirstName = this.accountSettings.FirstName;
    user.LastName = this.accountSettings.LastName;
    
    this.userService.addUser(user)
      .subscribe(value => this.accountRegistered(user),
        error => this.accountRegistrationError(error)
      );
  }

  accountRegistrationError(response: any) {
    console.log(response);
    this.errorMessage =  response.error;
  }
  
  accountRegistered(response: any) {

    this.dialogRef.close(response);
  }

}
