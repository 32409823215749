import { Component, OnInit, ViewChild, Inject, Optional, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator, MatSort, Sort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTable } from '@angular/material';
import { IUser } from '../../core/interfaces/user/IUser';
import { UserService } from '../../core/services/UserService';
import { IInvoice } from '../../core/interfaces/IInvoice';
import { InvoiceService } from '../../core/services/InvoiceService';
import { ICompany } from '../../core/interfaces/ICompany';
import { CompanyService } from '../../core/services/CompanyService';
import { TransactionLogService } from '../../core/services/TransactionLogService';
import { IContact } from '../../core/interfaces/IContact';
import { ITransactionLog } from '../../core/interfaces/invoice/ITransactionLog';
import { error } from '@angular/compiler/src/util';
import { AuthenticationService } from '../../core/services/AuthenticationService';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { Subject } from 'rxjs';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export interface TransLogData {
    name: string;
    id: number;
    money: number;
    date: string;
    ManualDiscount: any;
    FeeDescription: any;
    PartialTotalAmount: any;
    DeductionsTotalAmount: any;
    AllowancesTotalAmount: any;
    MileageTotalAmount: any;
    GrossSalary: any;
    EmployerTaxAmount: any;
    MunicipalityTaxAmount: any;
    NetSalary: any;
    TotalPayout: any;
    ServiceFee: any;
    Invoice: any;
    ReminderFee: any;
    InkassoFee: any;
    KronofogdenFee: any;
    CustomFee: any;
    TotalInvoiceAmount: any;
    PaidTotal: any;
    TotalInvoiceAmountIncVat: any;
    TotalVatAmount: any;
    InvoiceFeeReceiver: any;
    InvoiceFeeMember: any;
  InvoiceFee: any;
  isMobile: any;
  sortData: any;
  ExchangeFee: any;
  IsPreliminary: any;
  IsPayoutTransLog: any; 


}

@Component({
    selector: 'anms-transaction-logs',
  templateUrl: './transaction-logs.component.html',
  styleUrls: ['./transaction-logs.component.css'],
})

export class TransactionLogsComponent implements OnInit {

  public isAdmin: boolean = false;
  transactionLogs: Array<ITransactionLog>;
  expensesTotalAmount = 0;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
 // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<IInvoice>;
  dataSourceAdmin: MatTableDataSource<ITransactionLog>;
  //@ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  isLoading: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  displayedColumns = ['invoicenumber', 'customer', 'payment', 'payout'];
  displayedColumnsAdmin = [];
  user: any;
  invoiceData: Array<any>;
  statuses = [];
  payoutStatuses: any;
  selectedFilterStatus: any = { Id: 0, Name: 'Alla fakturor' };
  filter: string;
  sortedData;
  public listLength = "Laddar fakturor...";
  public search: string;
  searchUpdate = new Subject<string>();
  PaymentTransLog: any;
  PayoutTransLog: any;
  isMobile: any;
  Invoice: any;
    
  constructor(
    public dialog: MatDialog,
    private invoiceService: InvoiceService,
    private transactionLogService: TransactionLogService,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private loadingService: LoadingBarService,
    private userService: UserService) {
    this.dataSource = new MatTableDataSource<IInvoice>(this.invoiceData);
    this.dataSourceAdmin = new MatTableDataSource<ITransactionLog>(this.transactionLogs);

    // Debounce search.
    this.searchUpdate.pipe(
      debounceTime(600),
      distinctUntilChanged())
      .subscribe(value => {
        this.paginator.pageIndex = 0;
        this.filter = value.toString();
        this.getInvoices(null, 0, this.paginator.pageSize, value.toString());
      });
  }


  ngOnInit() {
        
      this.loadUser();
      this.isAdmin = this.userService.userHasRole("admin");
      this.setTableColumns(this.isAdmin);
      this.loadTransactions;
  }

  loadUser() {

    this.userService.getCurrentUser()
      .subscribe(value => this.userLoaded(value),
        error => console.log(error)
      );
    
  }

  userLoaded(user: any) {
    this.user = this.authenticationService.userData;
    this.isAdmin = this.userService.userHasRole("admin");

    if (this.isAdmin) {
      this.loadTransactions();
    }

    // Default selected item from storage
    var defaultItem = this.localStorageService.getItem("filterStatusDefault");
    if (defaultItem && this.isAdmin) {
      var item = JSON.parse(defaultItem);
      this.selectedFilterStatus = { Id: item.Id, Name: item.Name };

      this.getInvoices([item.Id], 0, 10, this.filter);

    }
    else {
      this.getInvoices(null, 0, 10, this.filter);
    }

   

  }

  setTableColumns(isAdmin: boolean) {
      if (isAdmin) {
        this.displayedColumns = ['invoicenumber', 'customer', 'user', 'payment', 'payout'];
      }
  }
    
  applyFilter(filterValue: string) {
      filterValue = filterValue.trim();         // Remove whitespace
      filterValue = filterValue.toLowerCase();  // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
  }

  onPaginatorChange(paginator: any) {debugger
    this.loadingService.start();
    this.getInvoices(null, paginator.pageIndex, paginator.pageSize, this.filter); debugger
  }

  getInvoices(statuses: Array<number> = null, pageIndex: number, pageSize: number, filter: string) {
    
    this.isLoading = true;

    // All invoices selected
    if (statuses != null && statuses.length == 1 && statuses[0] == 0) {
      statuses = null;
        
    }

    this.invoiceService.getInvoicesWithTransLogs(statuses, pageIndex, pageSize, filter)
      .subscribe(value => this.onInvociesRetrieved(value),
        error => console.log(error)          
    );
    
  }

  onInvociesRetrieved(response: any) {

    for (var i = 0; i < response.Invoices.length; i++) {
      if (response.Invoices[i].Status != undefined)
        response.Invoices[i].Status.Id = response.Invoices[i].Status.Id.toString();
    }

    this.invoiceData = response.Invoices;
    this.listLength = response.TotalInvoices;
    this.dataSource = new MatTableDataSource(this.invoiceData);
    //this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort.toArray()[0];

    for (var i = 0; i < this.invoiceData.length; i++) {
      if (this.invoiceData[i].ExchangeRateInvoicePaid != 0) {
        this.invoiceData[i].TotalVatAmount *= this.invoiceData[i].ExchangeRateInvoicePaid;
      }
    }

    // Set up custom search
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
        
      return (data.Status != null ? data.Status.Name.toLowerCase().includes(filter) : '') ||
        (data.Id == undefined ? '' : data.Id.toString().toLowerCase().includes(filter)) ||
        (data.InvoiceNumber == undefined ? '' : data.InvoiceNumber.toString().toLowerCase().includes(filter)) ||
        (data.Customer != null ? data.Customer.Name.toLowerCase() : '').includes(filter) ||
        (data.Created.toString().toLowerCase()).includes(filter) ||
        ((data.User == null || data.User == null) ? '' : data.User.FirstName.toLowerCase() + ' ' + data.User.LastName.toLowerCase()).includes(filter);

    };

    this.isLoading = false;
    this.loadingService.complete();
      
  }

  loadTransactions() {
    //this.transactionLogService.getTransactionLogs()
    //  .subscribe(transactions => this.transactionsLoaded(transactions),
    //    error => alert("An error occured!"));
  }

  transactionsLoaded(transactions: Array<ITransactionLog>) {

    this.transactionLogs = transactions;
    this.dataSourceAdmin = new MatTableDataSource<ITransactionLog>(this.transactionLogs);
    //this.dataSourceAdmin.paginator = this.paginator.toArray()[1];
    this.dataSourceAdmin.sort = this.sort.toArray()[1];

  }

  deleteTransactionLog(id: number) {
    var result = confirm("Vill du radera transaktionsraden?");

    if (result) {
      this.transactionLogService.deleteTransactionLog(id)
        .subscribe(response => this.onDeleteFinished(response), error => console.log(error));

    }
  }

  onDeleteFinished(response: any) {
    this.loadTransactions();
  }

  sortData(sort: Sort) {
    const data = this.invoiceData.slice();

    //if (!sort.active || sort.direction == '') {
    //  this.sortedData = data;
    //  return;
    //}
    debugger
    this.sortedData = data.sort((a, b) => {

      let isAsc = sort.direction == 'asc';
      switch (sort.active) {
        case 'id': return this.compare(a.Id, b.Id, isAsc);
        case 'customer': return this.compare(a.InvoiceNumber, b.InvoiceNumber, isAsc);
        case 'invoicenumber': return this.compare(a.Customer != null ? a.Customer.Name : '', b.Customer.Name != null ? b.Customer.Name : '', isAsc);
        case 'payment': return this.compare(a.PayoutStatus.Name, b.PayoutStatus.Name, isAsc);
        case 'payout': return this.compare(a.Status.Name, b.Status.Name, isAsc);
        default: return 0;
      }
    });

    this.dataSource.data = this.sortedData;
  }

  getPrelPayoutDate(date: Date) {
    
    const prelPayoutDate = new Date(date);
    prelPayoutDate.setDate(prelPayoutDate.getDate() + 1);

    return prelPayoutDate;
  }

  compare(a, b, isAsc) {
    console.log((a < b ? -1 : 1) * (isAsc ? 1 : -1));
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openDialog(action, invoice) {

    invoice.action = action;

    const dialogRef = this.dialog.open(TransactionLogMemberDialog, {
        
        width: '750px',
        data: invoice
    });
    dialogRef.afterClosed()
    console.log('The dialog is open');
  }

  openDialogAdmin(action, obj) {

    obj.action = action;
    const dialogRef = this.dialog.open(TransactionLogAdminDialog, {

      width: '750px',
      data: obj
    });
    dialogRef.afterClosed()
    console.log('The dialog is open');
  }


}

@Component({
  selector: 'transaction-log-member-dialog',
  templateUrl: 'transaction-log-member-dialog.html',
})
export class TransactionLogMemberDialog {
  invoice: any;
  TransactionLog: any;


  constructor(

    public dialogRef: MatDialogRef<TransactionLogMemberDialog>,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

      // Datat kommer in via parametern data och placeras i den lokala egenskapen invoice
      this.invoice = { ...data };
      
    // I den inskickade fakturaunderlaget så lade vi till parametern action som vi nu kontrollerar och sedan väljer trans-rad efter
    if (this.invoice.action == 'payment') {
      this.TransactionLog = this.invoice.TransactionLogs[0];
    }
    else if (this.invoice.action == 'payout') {
      this.TransactionLog = this.invoice.TransactionLogs[1];
      }

    }

    // Stäng popupen och returnera ev data
    closeDialog() {
        this.dialogRef.close(null);
    }

}


@Component({
  selector: 'transaction-log-admin-dialog',
  templateUrl: 'transaction-log-admin-dialog.html',
})
export class TransactionLogAdminDialog {
  action: string;
  local_data: any;
  Invoice: any;
  TransactionLog: any;


  constructor(

    public dialogRef: MatDialogRef<TransactionLogAdminDialog>,

    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("Öppnad faktura")
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  closeDialog() {
    this.dialogRef.close();
    console.log('The dialog was closed');
  }

}


