import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';
import { UserService } from '../../core/services/UserService';
import { MunicipalityService } from '../../core/services/municipalityService';
import { IUser } from '../../core/interfaces/user/IUser';
import { IFile } from '../../core/interfaces/IFile';
import { ITaxAdjustment } from '../../core/interfaces/user/ITaxAdjustment';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatPaginator, MatSortModule, MatSort, Sort } from '@angular/material';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataSource } from '@angular/cdk/collections';
import { CanComponentDeactivate } from '../../core/guards/CanDeactivate.Component';

import {
  selectorSettings, 
  ActionSettingsChangeTheme,
  ActionSettingsChangeAutoNightMode,
  ActionSettingsChangeAnimationsPage,
  ActionSettingsChangeAnimationsElements,
  SettingsState,
  ActionSettingsPersist,
} from '../settings.reducer';

@Component({
  selector: 'anms-companyuser-settings',
  templateUrl: './companyuser-settings.component.html',
  styleUrls: ['./companyuser-settings.component.scss']
})
export class CompanyUserSettingsComponent implements OnInit, OnDestroy, CanComponentDeactivate  {
  private unsubscribe$: Subject<void> = new Subject<void>();
    settings: SettingsState;
    public isAdmin: boolean = false;
    public dataSource: MatTableDataSource<ITaxAdjustment>;
    public displayedColumns
    public taxadjustmentsEdited: boolean = false;
  @ViewChild('f', { static: false }) form;
  countries = [];
  invoice = { Id: 0, Country: { Name: 'Sverige', Eu: true }, FirstName: null, Phone: null, Email: null, Vat: null, OrgNumber: null, Address: null, Co: null, Zip: null, City: null, DeliveryAddress: null, DeliveryCo: null, DeliveryZip: null, DeliveryCity: null, BankName: null, ClearingNumber: null, AccountNumber: null, Bankgiro: null, Plusgiro: null, Iban: null, Bic: null };

    
  themes = [
    { value: 'DEFAULT-THEME', label: 'Blue' },
    { value: 'LIGHT-THEME', label: 'Light' },
    { value: 'NATURE-THEME', label: 'Nature' },
    { value: 'BLACK-THEME', label: 'Dark' }
  ];

  user: IUser;
  isLoading: boolean = true;
  validationErrorMessage: string;



  constructor(
      private router: Router,
      private store: Store<any>,
      private userService: UserService,
      private route: ActivatedRoute,
      private municipalityService: MunicipalityService,
      private loadingService: LoadingBarService,
      public snackBar: MatSnackBar,
      public dialog: MatDialog
  ) {

  }
  
  ngOnInit() {
      this.loadingService.start();
      this.loadUser();
      this.getCountries();
      this.isAdmin = this.userService.userHasRole("admin");
      this.setTableColumns(this.isAdmin);
  }

  canDeactivate(): boolean {

      if (this.form == null)
      {
          return true;
      }
      
      return this.form.dirty || this.taxadjustmentsEdited
          ? confirm('Vill du avsluta utan att spara?')
          : true;
  }

    setTableColumns(isAdmin: boolean) {
        if (isAdmin) {
            this.displayedColumns = ['type', 'startdate', 'enddate', 'maxamount', 'temptax', 'attachment'];
        }
        else {
          this.displayedColumns = ['type', 'startdate', 'enddate', 'temptax', 'attachment'];
        }
    }

  loadUser()
  {
      this.userService.getCurrentUser()
          .subscribe(value => this.userLoaded(value),
          error => console.log(error)
      );
  }

  userLoaded(user: IUser)
  {
      this.user = user;
      this.dataSource = new MatTableDataSource(user.TaxAdjustments);
      this.isLoading = false;
      this.loadingService.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit()
  { 
      this.isLoading = true;
      this.userService.updatetUser(this.user)
          .subscribe(
          res => this.userUpdated(res),
          err => this.onUserError(err),
              () => console.log('HTTP request completed.')
      );
  }

  userUpdated(response: any)
  {
      this.router.navigate(['/']);
  }

  onUserError(errorMessage: any)
  {
      this.isLoading = false;
      this.showMessage(errorMessage);
  }

  showMessage(message: string) {
      let config = new MatSnackBarConfig();
      config.duration = 7000;
      this.snackBar.open(message, undefined, config);
  }

  showFile(file: IFile) {
        window.open(file.Url);
  }

  
  getCountries() {
    this.userService.getCountries()
      .subscribe(value => this.countries = value,
        error => this.onError(error)
      );
  }

  onError(error: any) {
    alert('An error occured');
    console.log(error);
  }

  getCountryName(countryId: number) { 
    for (var i = 0; i < this.countries.length; i++) {
      if (this.countries[i].Id == countryId) {

        this.user.Country.Name = this.countries[i].Name;
        this.user.Country.Eu = this.countries[i].Eu;
      }
    }
  }

  saveValidates() {    
    //Country must be set
    if (this.user.Country.Name == null) {
      this.validationErrorMessage = "Du måste ange ett land";
      return false;
    }

    //Email address must be given
    if (this.user.Email == null || this.user.Email == "") {
      this.validationErrorMessage = "Du måste ange en mailadress";
      return false;
    }

    //If Sweden
    if (this.user.Country.Name == 'Sverige') {

      //Orgnr must be given
      if (this.user.OrgNumber == null || this.user.OrgNumber == "") {
        this.validationErrorMessage = "Du måste ange ett organisationsnummer";
        return false;
      }

      //Bank account, Bankgiro or Plusgiro must be used
      if (((this.user.BankName == null || this.user.BankName == "") || (this.user.AccountNumber == null || this.user.AccountNumber == "") || (this.user.ClearingNumber == null || this.user.ClearingNumber == "")) && (this.user.Bankgiro == null || this.user.Bankgiro == "") && (this.user.Plusgiro == null || this.user.Plusgiro == "")) {
        this.validationErrorMessage = "Du måste ange bankuppgifter, Bankgiro eller Plusgiro";
        return false;
      }
    }

    //If not Sweden
    if (this.user.Country.Name != null && this.user.Country.Name != 'Sverige') {

      if (this.user.CompanyName == null || this.user.CompanyName == "") {
        this.validationErrorMessage = "Du måste ange ett namn";
        return false;
      }

      if (this.user.Address == null || this.user.Address == "") {
        this.validationErrorMessage = "Du måste ange en gatuadress";
        return false;
      }

      if (this.user.Zip == null || this.user.Zip == "") {
        this.validationErrorMessage = "Du måste ange ett postnummer";
        return false;
      }

      if (this.user.City == null || this.user.City == "") {
        this.validationErrorMessage = "Du måste ange en stad";
        return false;
      }

      if (this.user.Iban == null || this.user.Iban == "") {
        this.validationErrorMessage = "Du måste ange IBAN";
        return false;
      }

      if (this.user.Bic == null || this.user.Bic == "") {
        this.validationErrorMessage = "Du måste ange BIC";
        return false;
      }

      else {
        this.validationErrorMessage = ""
        return true;
      }

    }

    //If not Sweden, but EU country
    if (this.user.Country.Eu && this.user.Country.Name != 'Sverige') {

      //Orgnr must be given
      if (this.user.Vat == null || this.user.Vat == "") {
        this.validationErrorMessage = "Du måste ange VAT";
        return false;
      }
    }

    else {
      this.validationErrorMessage = ""
      return true;
    }
  }

  onThemeSelect({ value: theme }) {
    this.store.dispatch(new ActionSettingsChangeTheme({ theme }));
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  onAutoNightModeToggle({ checked: autoNightMode }) {
    this.store.dispatch(
      new ActionSettingsChangeAutoNightMode({ autoNightMode })
    );
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  onPageAnimationsToggle({ checked: pageAnimations }) {
    this.store.dispatch(
      new ActionSettingsChangeAnimationsPage({ pageAnimations })
    );
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  onElementsAnimationsToggle({ checked: elementsAnimations }) {
    this.store.dispatch(
      new ActionSettingsChangeAnimationsElements({ elementsAnimations })
    );
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
    }

    config = {
        disableClose: false,
        panelClass: 'full-screen-modal',
        hasBackdrop: true,
        backdropClass: '',
        width: '500px',
        maxWidth: '100vw',
        height: '100%',
        position: {
            top: '',
            bottom: '',
            left: '',
            right: ''
        },
        data: {
            invoiceItemGroup: {},
            customerTemplate: {},
            customer: {},
            contact: {},
            companyId: 0,
            status: {}
        },
        dataTemplate: {
            Id: 0,
            Country: { Id: 1 },
            Type: {},
            HoursWorked: undefined,
            StartDate: undefined,
            EndDate: undefined,
            Comment: undefined,
            InvoiceId: 0
        }
    };

}
