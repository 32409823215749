import { Component, OnInit } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpModule, Headers, RequestOptions } from '@angular/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, User } from "../../../core/services/AuthenticationService"
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { UserService } from '../../../core/services/UserService';

@Component({
  selector: 'anms-emailpass',
  templateUrl: './emailpass.component.html',
  styleUrls: ['./emailpass.component.css']
})
export class EmailpassComponent implements OnInit {
  public user: CreateUser = this.getUser();
  public createAccountMethod: number = 1; // 0, default, 1 = BankId, 2 = Manual
    routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
    versions = env.versions;
    public errorMsg = '';
    loading = false;
    returnUrl: string;
    public appId: number = env.appId;

    message: string = 'Felaktigt lösenord!';
    actionButtonLabel: string = 'Retry';
    action: boolean = false;
    setAutoHide: boolean = true;
    autoHide: number = 10000;
    tosAccepted: any;
  errorMessage: any = "";
  bankIdUrl = "";

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        private userService: UserService,
      
    ) { }

  ngOnInit() {
    this.bankIdUrl = env.bankIdUrl;
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.user = this.getUser();
    }

  onSubmit() {
      
        this.createAccount();       
  }

  redirectToURL(url) {
    window.location.href = url;
  }

  validateForm() {

    if (this.appId == 1) {

      if (this.user.firstname == "") {
        this.errorMessage = "Förnamn saknas";
        return false;
      }
       

      if (this.user.lastname == "") {
        this.errorMessage = "Efternamn saknas";
        return false;
      }
    }

    if (this.appId == 2) {
      if (this.user.companyname == "") {
        this.errorMessage = "Företagsnamn saknas";
        return false;
      }
    }

    if (this.user.email == "") {
      this.errorMessage = "Email saknas";
      return false;
    }

    if (this.user.password == "") {
      this.errorMessage = "Lösenord saknas";
      return false;
    }

    if (this.user.repeatpassword == "") {
      this.errorMessage = "Upprepa lösenord saknas";
      return false;
    }

    if (this.user.phone == "") {
      this.errorMessage = "Telefon saknas";
      return false;
    }

    else {
      this.errorMessage = "";
      return true;
    }

  }

  createAccount() {
        this.loading = true;
        this.authenticationService.createAccount(this.user)
            .subscribe(
                data => {
                  
                    // No error
                    if (JSON.stringify(data) != '"error"') {

                        // Auto login
                        this.authenticationService.login(this.user.email, this.user.password)
                            .subscribe(
                                data => {
                                    this.userService.setHttpOptions();
                                    this.router.navigateByUrl("/settings")
                                },
                                error => {

                                }
                            );

                    }

                    this.loading = false;
                },
                error => {
                    alert('Error')
                    this.loading = false;
                });
    }


    getUser() {
        return new CreateUser("", "", "", "", "", "", "", "", "");
    }

    openLink(link: string) {
        window.open(link, '_blank');
    }
}


export class CreateUser {
    constructor
        (
            public username: string,
            public password: string,
            public firstname: string,
            public lastname: string,
            public companyname: string,
            public email: string,
            public phone: string,
            public DiscountCode: string,
            public repeatpassword: string
        ) { }
}
