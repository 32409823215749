import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../services/AuthenticationService';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { RequestOptions } from "@angular/http";


@Injectable()
export class InvoiceService {
  public apiAuthUrl: string;
  private apiBaseUrl: string;
  public token: string;
  private userString: string;
  private httpOptions: any;
  private handleError: HandleError;
    
  constructor(
      private http: HttpClient,
      private router: Router,
      private authenticationService: AuthenticationService,
      httpErrorHandler: HttpErrorHandler,
      public snackBar: MatSnackBar,
      private route: ActivatedRoute)
  {
      this.apiBaseUrl = env.hostApi;
      this.setHttpOptions();
  }
    
  showMessage(message: string)
  {
      let config = new MatSnackBarConfig();
      config.duration = 7000;
      this.snackBar.open(message, undefined, config);
  }

  getInvoices(statuses: Array<number> = null, pageIndex: number = 0, pageSize: number = 10, filter: string): Observable<any> {

      var url = this.apiBaseUrl + 'Invoices?';
        
      if (statuses != null){
          url += '&statuses=' + statuses.join();
      }

      if (pageIndex != null){
          url += '&pageIndex=' + pageIndex;
      }

      if (pageSize != null) {
          url += '&pageSize=' + pageSize;
      }

      if (filter != null) {
          url += '&filter=' + filter;
      }

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          tap((response: any) => this.onInvoicesFinished(response)),
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
}

  getInvoicesByOrganization(organizationId: number, statuses: Array<number> = null, pageIndex: number = 0, pageSize: number = 10, filter: string): Observable<any> {

    var url = this.apiBaseUrl + 'Organizations/' + organizationId.toString() + '/Invoices?';

    if (statuses != null) {
      url += '&statuses=' + statuses.join();
    }

    if (pageIndex != null) {
      url += '&pageIndex=' + pageIndex;
    }

    if (pageSize != null) {
      url += '&pageSize=' + pageSize;
    }

    if (filter != null) {
      url += '&filter=' + filter;
    }

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onInvoicesFinished(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getInvoicesAdmin(statuses: Array<number> = null, pageIndex: number = 0, pageSize: number = 10, filter: string): Observable<any> {

    var url = this.apiBaseUrl + 'InvoicesAdmin?';

    if (statuses != null) {
      url += '&statuses=' + statuses.join();
    }

    if (pageIndex != null) {
      url += '&pageIndex=' + pageIndex;
    }

    if (pageSize != null) {
      url += '&pageSize=' + pageSize;
    }

    if (filter != null) {
      url += '&filter=' + filter;
    }

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onInvoicesFinished(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getInvoicesWithTransLogs(statuses: Array<number> = null, pageIndex: number = 0, pageSize: number = 10, filter: string): Observable<any> {

  var url = this.apiBaseUrl + 'Invoices/WithTransLogs?';

  if (statuses != null) {
    url += '&statuses=' + statuses.join();
  }

  if (pageIndex != null) {
    url += '&pageIndex=' + pageIndex;
  }

  if (pageSize != null) {
    url += '&pageSize=' + pageSize;
  }

  if (filter != null) {
    url += '&filter=' + filter;
    }
  
  return this.http.get<any>(url, this.httpOptions)
    .pipe(
      tap((response: any) => this.onInvoicesFinished(response)),
      catchError(
        (err: any) => {
          return new Observable<any>();
        }
      ));
}

  onInvoicesFinished(response: any) {
      console.log(response);
  }

  getInvoice(id: number): Observable<any> {

      var url = this.apiBaseUrl + 'Invoice/' + id;
        

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          catchError(
              (err: any) => {

                  console.log(err)
                  return new Observable<any>();
              }
          ));
  }

  getCombinedInvoiceTool(id: number): Observable<any> {

    var url = this.apiBaseUrl + 'CombinedInvoiceTool/' + id;


    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        catchError(
          (err: any) => {

            console.log(err)
            return new Observable<any>();
          }
        ));
  }

  getInvoiceAdminView(id: number): Observable<any> {

    var url = this.apiBaseUrl + 'InvoiceAdminView/' + id;


    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        catchError(
          (err: any) => {

            console.log(err)
            return new Observable<any>();
          }
        ));
  }

  getCombinedInvoiceSummary(id: number): Observable<any> {

      var url = this.apiBaseUrl + 'CombinedInvoice/' + id;


      return this.http.get<any>(url, this.httpOptions)
        .pipe(
          catchError(
            (err: any) => {

              console.log(err)
              return new Observable<any>();
            }
          ));
    }

  copyInvoice(id: number): Observable<any> {

      var url = this.apiBaseUrl + 'Invoices/' + id + '/copy';
        
      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
  }

  copyInvoiceCredit(id: number): Observable<any> {

      var url = this.apiBaseUrl + 'Invoices/' + id + '/copycredit';

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
  }

  getInvoiceStatuses(): Observable<any> {

      var url = this.apiBaseUrl + 'Invoice/statuses';
        
      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
  }

  getTimeReportStatuses(): Observable<any> {

      var url = this.apiBaseUrl + 'Invoice/TimeReportStatuses';

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
  }

  getPayoutStatuses(): Observable<any> {

      var url = this.apiBaseUrl + 'PayoutStatuses';

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
  }

  sendMessage(message: string, invoiceId: number): Observable<any> {


      var url = this.apiBaseUrl + 'Invoice/' + invoiceId + '/addMessage';
      var data = JSON.stringify(message);

      return this.http.post<any>(url, data, this.httpOptions)
          .pipe(
          tap((response: any) => this.showMessage('Meddelandet skickat!')),
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          )
          );
}

  sendFileUploadedNotification(invoiceId: number, fileId: string): Observable<any> {

  var url = this.apiBaseUrl + 'Invoice/' + invoiceId + '/SendFileNotification';
  var data = JSON.stringify(fileId);

  return this.http.post<any>(url, data, this.httpOptions)
    .pipe(
      tap((response: any) => this.showMessage('Meddelandet skickat!')),
      catchError(
        (err: any) => {
          return new Observable<any>();
        }
      )
    );
}

  getInvoiceShareTypes(): Observable<any> {

      var url = this.apiBaseUrl + 'Invoice/SalaryShareTypes';

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
}

  getGlobalServiceFee(express: boolean) {
    var url = this.apiBaseUrl + 'GlobalServiceFee?express=' + express;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }
    
  createInvoice(invoice: any, message: string, redirect: string): Observable<any> {
    var c = JSON.stringify(invoice);
      var url = this.apiBaseUrl + 'Invoice';

      return this.http.post<any>(url, c, this.httpOptions)
          .pipe(
          tap((response: any) => this.onInvoiceSaved(response, redirect, message)),
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
  }

  createCombinedInvoice(invoice: any, message: string, redirect: string): Observable<any> {
  var c = JSON.stringify(invoice);
  var url = this.apiBaseUrl + 'CombinedInvoices';

  return this.http.post<any>(url, c, this.httpOptions)
    .pipe(
      tap((response: any) => this.onInvoiceSaved(response, redirect, message)),
      catchError(
        (err: any) => {
          return new Observable<any>();
        }
      ));
}

  updateInvoice(invoice: any, message: string, redirect: string): Observable<any> {
      
      var c = JSON.stringify(invoice);
      var url = this.apiBaseUrl + 'Invoice';

      return this.http.put<any>(url, c, this.httpOptions)
          .pipe(
          tap((response: any) => this.onInvoiceSaved(response, redirect, message)),
          catchError(
              (err: any) => {
                  this.showMessage(err.error.ExceptionMessage);
                  return new Observable<any>();
              }
          ));
  }

  updateInvoiceStatus(invoiceId: number, statusId: number, message: string, sendCustomerMessage: boolean): Observable<any> {

      var url = this.apiBaseUrl + 'Invoice/' + invoiceId + '/Status?statusId=' + statusId + '&sendCustomerMessage=' + sendCustomerMessage;

      return this.http.put<any>(url, null, this.httpOptions)
          .pipe(
          tap((response: any) => this.onInvoiceSaved(response, null, message)),
          catchError(
              (err: any) => {
                  this.showMessage(err.error.ExceptionMessage);
                  return new Observable<any>();
              }
          ));
  }

  updatePayoutStatus(invoiceId: number, statusId: number, message: string, sendCustomerMessage: boolean): Observable<any> {

      var url = this.apiBaseUrl + 'Invoice/' + invoiceId + '/PayoutStatus?statusId=' + statusId + '&sendCustomerMessage=' + sendCustomerMessage;

      return this.http.put<any>(url, null, this.httpOptions)
          .pipe(
          tap((response: any) => this.onInvoiceSaved(response, null, message)),
          catchError(
              (err: any) => {
                  this.showMessage(err.error.ExceptionMessage);
                  return new Observable<any>();
              }
          ));
  }

  updateInvoiceSummary(invoice: any, sendEmail: boolean = true, createSubInvoices = true): Observable<any> {

    var c = JSON.stringify(invoice);
    var url = this.apiBaseUrl + 'Invoices/' + invoice.Id + '/Summary?sendEmail=' + sendEmail + '&createSubInvoices=' + createSubInvoices;

    this.setHttpOptions();


      return this.http.put<any>(url, c, this.httpOptions)
        .pipe(
          tap((response: any) => this.onInvoiceSaved(response, null,'Fakturan uppdaterad!')),
          catchError(
            (err: any) => {
                  console.log('Ett fel inträffade!')
              console.log(err);
              alert('fel')
                  this.showMessage(err.error.ExceptionMessage);
                  return new Observable<any>();
              }
          ));
}

  updatePayoutDate(invoiceId: number, payoutDate: Date): Observable<any> {

  var data = JSON.stringify(payoutDate);
  var url = this.apiBaseUrl + 'Invoices/' + invoiceId + '/UpdatePayoutDate';

  this.setHttpOptions();

  return this.http.put<any>(url, data, this.httpOptions)
    .pipe(
      tap((response: any) => this.onInvoiceSaved(response, null, 'Utbetalning registrerad för faktura-id ' + invoiceId)),
      catchError(
        (err: any) => {
          console.log('Ett fel inträffade!')
          console.log(err);
          this.showMessage(err.error.ExceptionMessage);
          return new Observable<any>();
        }
      ));
}

  onInvoiceSaved(response: any, redirect: string, message: string) {

      if (message != null)
          this.showMessage(message);

      if (redirect != null)
          this.router.navigate([redirect]);

  }

  deleteInvoice(id: any): Observable<any> {
        
      var url = this.apiBaseUrl + 'Invoice/' + id;

      return this.http.delete<any>(url, this.httpOptions)
          .pipe(
          tap((response: any) => this.onInvoiceDeleted(response)),
          catchError(
              (err: any) => {
                    
                  this.showMessage(err.error.ExceptionMessage);
                  return new Observable<any>();

              }
          ));
  }

  onInvoiceDeleted(response: any) {
      this.showMessage("Fakturan raderad!");
  }

  uploadDocument(fileToUpload: File, id: string, fileTypeName: string = ""): Observable<boolean> {

    var url = this.apiBaseUrl + 'Upload/document/' + id + '?fileTypeName=' + fileTypeName;
      const formData: FormData = new FormData();
      formData.append('fileKey', fileToUpload, fileToUpload.name);

      var bearerToken = 'bearer ' + this.authenticationService.token;
      var httpOptions = {
          headers: new HttpHeaders({
              'Authorization': bearerToken
          })
      };

      return this.http.post<any>(url, formData, httpOptions)
          .pipe(
          tap((response: any) => console.log(response)),
          catchError(
              (err: any) => {
                  this.showMessage("Ett fel inträffade, försök igen!")
                  return new Observable<any>();

              }
          ));
  }
    
  uploadDocumentkkk(file: File): Observable<any> {
        
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);

      //let headers = new Headers();
      //  /** In Angular 5, including the header Content-Type can invalidate your request */
      //headers.append('Content-Type', 'multipart/form-data');
      //headers.append('Accept', 'application/json');
      //let options = new RequestOptions({ headers: headers });

      var bearerToken = 'bearer ' + this.authenticationService.token;

      var httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'multipart/form-data',
              'Authorization': bearerToken
          })
      };

      //let headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        //headers.append('Content-Type', 'multipart/form-data');
        //headers.append('Accept', 'application/json');
        //let options = new RequestOptions({ headers: headers });
        //this.http.post(`${this.apiEndPoint}`, formData, options)
        //    .map(res => res.json())
        //    .catch(error => Observable.throw(error))
        //    .subscribe(
        //    data => console.log('success'),
        //    error => console.log(error)
        //    )
        
      var url = this.apiBaseUrl + 'Upload/document';

      return this.http.post<any>(url, formData, httpOptions)
          .pipe(
          tap((response: any) => console.log(response)),
          catchError(
              (err: any) => {

                  console.log(err)
                  //if (err.status == '401') {
                  //    this.router.navigate(['/login']);
                  //}
                  return new Observable<any>();

              }
          ));
  }
    
  getOverviewdReport(invoiceId: number): Observable<any> {
        
      var url = this.apiBaseUrl + 'Document/TravelReport/' + invoiceId.toString() + '/Pdf';

      var bearerToken = 'bearer ' + this.authenticationService.token;

      var httpOptions = {
          responseType: 'blob' as 'json',
          headers: new HttpHeaders({
              'Authorization': bearerToken
          })
      };

      return this.http.get(url, httpOptions);
  }

  setHttpOptions() {
      var bearerToken = 'bearer ' + this.authenticationService.token;

      this.httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': bearerToken
          })
      };
  }
}

