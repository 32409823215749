import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, catchError, map, tap } from 'rxjs/operators';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { CompanyService } from '../../core/services/CompanyService';
import { UserService } from '../../core/services/UserService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { EditCustomerDialogComponent } from '../../shared/customer/EditCustomerDialog.Component';
import { ICompany } from '../../core/interfaces/ICompany';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatPaginator, MatTableDataSource, MatSortModule, MatSort, Sort } from '@angular/material';
import { finalize } from "rxjs/internal/operators/finalize";
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';
import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EditAccountDialogComponent } from '../../shared/account/EditAccountDialog.Component';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  public isParentAccount: boolean = false;
  public isCompanyUser: boolean = false;
  public isAdmin: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  displayedColumns = ['name', 'companyname', 'memberNumber', 'personalNumber', 'email', 'created', 'lastLogin', 'suspended', 'edit'];
  customerData: Array<any>;
  sortedData;
  dataSource;
  downloadingReport: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  constructor(public store: Store<any>,
      public snackBar: MatSnackBar,
      private companyService: CompanyService,
      private userService: UserService,
      private invoiceService: InvoiceService,
      private router: Router,
      private loadingService: LoadingBarService,
      public dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadingService.start();
    this.isAdmin = this.userService.userHasRole('admin');
    this.isParentAccount = this.userService.userIsParentAccount();
    this.isCompanyUser = this.userService.userHasRole('companyuser');
    this.getUsers();
  }

  applyFilter(filterValue: string) {
      
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
  }
  
  getExcelReport()
  {
      this.downloadingReport = true;

      this.companyService.GetCompanyUsersFile(1)
          .subscribe(x => {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(newBlob);
                  return;
              }

              // For other browsers: 
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob);

              var link = document.createElement('a');
              link.href = data;
              link.download = "Medlemslista.xlsx";
              // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

              setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(data);
                  link.remove();
              }, 100);

              this.downloadingReport = false;
          });
  }

  sortData(sort: Sort) {
      const data = this.customerData.slice();

      if (!sort.active || sort.direction == '') {
          this.sortedData = data;
          return;
      }

      this.sortedData = data.sort((a, b) => {
          let isAsc = sort.direction == 'asc';
          switch (sort.active) {
              case 'name': return this.compare(a.FirstName, b.FirstName, isAsc);
              case 'companynamename': return this.compare(a.CompanyName, b.CompanyName, isAsc);
              case 'memberNumber': return this.compare(a.MemberNumber, b.MemberNumber, isAsc);
              case 'personalNumber': return this.compare(a.PersonalNumber, b.PersonalNumber, isAsc);
              case 'email': return this.compare(a.Email, b.Email, isAsc);
              case 'created': return this.compare(a.Created, b.Created, isAsc);
              case 'lastLogin': return this.compare(a.LastLogin, b.LastLogin, isAsc);
              default: return 0;
          }
      });
      
      this.dataSource.data = this.sortedData;
  }

  compare(a, b, isAsc) {
      console.log((a < b ? -1 : 1) * (isAsc ? 1 : -1));
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  ngOnDestroy(): void {
  }

  getUsers() {

      this.userService.getUsers()
          .subscribe(value => this.onUsersRetrieved(value),
            error => console.log(error)
          );
  }

  addInvoice(user: any)
  {    
      this.invoiceService.createInvoice({ User: { Id: user.Id } }, 'Fakturan skapades!', undefined)
          .subscribe(value => this.onInvoiceCreated(value),
          error => console.log(error)
          );
  }

  delete(user: any) {
      var result = confirm("Vill du radera " + user.FirstName + " " + user.LastName + "?");
      if (result) {
          this.userService.deleteUser(user.Id)
              .subscribe(value => this.onInvoiceDeleted(user.Id),
              error => console.log(error)
              );
      }
      
  }

  onInvoiceDeleted(id: number)
  {
      
      for (var i = 0; i < this.customerData.length; i++)
      {
          if (this.customerData[i].Id == id)
          {
              this.customerData.splice(i, 1);
          }
      }

      this.dataSource = new MatTableDataSource(this.customerData);
  }
    
  onInvoiceCreated(response: any)
  {
      this.router.navigate(['/invoice-tool', response.Id]);
      console.log(response);
  }

  onUsersRetrieved(users: any)
  {
      console.log(users);

      this.customerData = users;
      this.dataSource = new MatTableDataSource(this.customerData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      
      // Set up custom search
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
          return (data.FirstName == undefined ? '' : data.FirstName).toLowerCase().includes(filter) ||
              (data.FirstName + ' ' + data.LastName).toLowerCase().includes(filter) ||
              data.MemberNumber.toString().includes(filter) ||
              (data.PersonalNumber == undefined ? '' : data.PersonalNumber).toLowerCase().includes(filter) ||
              (data.Email == undefined ? '' : data.Email).toLowerCase().includes(filter) ||
              (data.CompanyName == undefined ? '' : data.CompanyName).toLowerCase().includes(filter);
      };

      this.loadingService.complete();
  }

  edit(user: any) {
    var newWindow = window.open("#/users/" + user.Id);
  }

  editModal(user: any) {

    const dialogRef = this.dialog.open(EditAccountDialogComponent, {
      width: '350px',
      height: '440px',
      data: {
        id: user.User.Guid
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers();
    });

  }

  createNewUser(user: any) {

    const dialogRef = this.dialog.open(EditAccountDialogComponent, {
      width: '350px',
      height: '440px',
      data: {
        id: 0
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers();
    });

  }
  
}
